import React from 'react';

import styled from 'styled-components';

import { ColorTypes, FontSizeTypes, FontWeightTypes, color, fontSize, fontWeight } from 'styles/assets';

interface Props {
  dataId?: string;
  text?: string;
  fontSize?: FontSizeTypes;
  fontWeight?: FontWeightTypes;
  fontColor?: ColorTypes;
  numberOfLines?: number;
  width?: string;
  textDecoration?: 'none' | 'line-through' | 'overline' | 'underline' | 'initial' | 'inherit';
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  wordBreak?: 'normal' | 'break-word';
  whiteSpace?: 'normal' | 'nowrap';
  dataTestId?: string;
}

const StyledText = ({
  dataId,
  text = '',
  fontSize = 'base',
  fontWeight = 'medium',
  fontColor = 'black',
  numberOfLines,
  width = 'auto',
  textDecoration = 'none',
  textAlign = 'initial',
  wordBreak = 'normal',
  whiteSpace = 'normal',
  dataTestId = '',
}: Props) => {
  return (
    <Wrapper textAlign={textAlign} width={width}>
      <Text
        data-id={dataId}
        data-testid={dataTestId}
        title={numberOfLines ? text : undefined}
        textDecoration={textDecoration}
        numberOfLines={numberOfLines}
        fontSize={fontSize}
        fontColor={fontColor}
        fontWeight={fontWeight}
        wordBreak={wordBreak}
        whiteSpace={whiteSpace}
      >
        {String(text)
          .split(/\n/g)
          .map((line, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <br />}
              {line}
            </React.Fragment>
          ))}
      </Text>
    </Wrapper>
  );
};

export default StyledText;

const Wrapper = styled.div<{
  width?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
}>`
  width: ${(props) => props.width};
  text-align: ${(props) => props.textAlign};
`;

const Text = styled.p<{
  dataId?: string;
  numberOfLines?: number;
  fontSize?: FontSizeTypes;
  fontWeight?: FontWeightTypes;
  fontColor?: ColorTypes;
  textDecoration?: 'none' | 'line-through' | 'overline' | 'underline' | 'initial' | 'inherit';
  wordBreak?: 'normal' | 'break-word';
  whiteSpace?: 'normal' | 'nowrap';
}>`
  overflow: hidden;
  display: ${(props) => (props.numberOfLines ? '-webkit-box' : 'inherit')};
  text-overflow: ${(props) => (props.numberOfLines ? 'ellipsis' : 'inherit')};
  -webkit-box-orient: ${(props) => (props.numberOfLines ? 'vertical' : 'inherit')};
  -webkit-line-clamp: ${(props) => (props.numberOfLines ? props.numberOfLines : 'inherit')};
  font-size: ${(props) => fontSize[props?.fontSize as FontSizeTypes].size};
  line-height: ${(props) => fontSize[props?.fontSize as FontSizeTypes]?.height};
  font-weight: ${(props) => fontWeight[props?.fontWeight as FontWeightTypes]};
  color: ${(props) => color[props?.fontColor as ColorTypes]};
  text-decoration: ${(props) => props.textDecoration};
  white-space: ${(props) => props.whiteSpace};
  word-break: ${(props) => props.wordBreak};
`;
