import { useState } from 'react';

import { CreateOverlayElement, useOverlayContext } from 'providers/overlay-provider';

let elementId = 0;

export const useOverlay = () => {
  const { mount, unmount } = useOverlayContext();
  const [id] = useState(() => String(elementId++));

  return {
    open: (props: CreateOverlayElement) => {
      mount(id, props);
    },
    close: () => unmount(id),
  };
};
