import { ReactNode } from 'react';

import MenuIcon from 'components/Icons/MenuIcon';
import ButtonShape from 'components/common/button/ButtonShape';
import StyledText from 'components/common/text/StyledText';
import { Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';
import { buttonHoverStyleTheme } from 'styles/assets/elements/buttonTheme/ButtonHoverStyleTheme';
import { ButtonSizeTypes, buttonSizeTheme } from 'styles/assets/elements/buttonTheme/ButtonSizeTheme';
import { ButtonStyleTypes, buttonStyleTheme } from 'styles/assets/elements/buttonTheme/ButtonStyleTheme';

interface Props {
  sizeType?: ButtonSizeTypes;
  styleType?: ButtonStyleTypes;
  children?: ReactNode;
  text?: string;
  onPress?: () => void;
  disabled?: boolean;
  width?: string;
  borderRadius?: string;
  icon?: 'right' | 'left' | false;
  type?: string;
  iconPosition?: 'left' | 'left-center' | 'right-center' | 'right';
  customIcon?: ReactNode;
}

const NormalButton = ({
  sizeType = 'xLarge',
  styleType = 'primary',
  children,
  text,
  onPress = () => {},
  disabled = false,
  width,
  borderRadius,
  icon = false,
  type = 'button',
  iconPosition = 'left-center',
  customIcon,
}: Props) => {
  const currentStyle = disabled ? buttonStyleTheme.disabled : buttonStyleTheme[styleType];
  const hoverStyle = disabled ? buttonStyleTheme.disabled : buttonHoverStyleTheme[styleType];
  const iconColor = currentStyle.fontColor;

  return (
    <ButtonShape
      type={type}
      onPress={onPress}
      disabled={disabled}
      backgroundColor={color[currentStyle.backgroundColor]}
      height={buttonSizeTheme[sizeType].buttonHeight}
      border={buttonStyleTheme[disabled ? 'disabled' : styleType].border}
      width={width}
      borderRadius={borderRadius}
      hover={hoverStyle}
    >
      {icon === 'left' && <MenuIcon type="chevron_left" fill={iconColor} />}
      {customIcon && iconPosition === 'left' && customIcon}
      {children}
      <Row justifyContent="center" gap="6px" alignItems="center">
        {customIcon && iconPosition === 'left-center' && customIcon}
        {text && (
          <StyledText
            text={text}
            fontSize={buttonSizeTheme[sizeType].fontSize}
            fontWeight={buttonSizeTheme[sizeType].fontWeight}
            fontColor={buttonStyleTheme[disabled ? 'disabled' : styleType].fontColor}
            textAlign="center"
          />
        )}
        {customIcon && iconPosition === 'right-center' && customIcon}
      </Row>

      {customIcon && iconPosition === 'right' && customIcon}
      {icon === 'right' && <MenuIcon type="chevron_right" fill={iconColor} />}
    </ButtonShape>
  );
};

export default NormalButton;
