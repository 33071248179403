import styled from 'styled-components';

import { color } from 'styles/assets';

interface Props {
  children: React.ReactNode;
  transparent?: boolean;
  visible?: boolean;
  backgroundOnPress?: () => void;
  maxWidth?: string;
  width?: string;
  padding?: string;
}

const ModalForm = ({
  children,
  transparent = false,
  visible = true,
  backgroundOnPress = () => {},
  maxWidth,
  width,
  padding = '30px',
}: Props) => {
  return (
    <Container aria-label="modal-form-container" visible={visible}>
      <Background aria-label="modal-form-background" transparent={transparent} onClick={backgroundOnPress} />
      <ModalContainer width={width} maxWidth={maxWidth} padding={padding}>
        {children}
      </ModalContainer>
    </Container>
  );
};

export default ModalForm;

const Container = styled.div<{ visible?: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1202;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const Background = styled.div<{ transparent?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${color.black};
  opacity: ${(props) => (props.transparent ? 0 : 0.3)};
`;

const ModalContainer = styled.div<{
  maxWidth?: string;
  width?: string;
  padding?: string;
}>`
  background-color: ${color.white};
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding};
  max-height: 90vh;
  overflow-y: auto;
  max-width: ${(props) => props.maxWidth || '100%'};
  width: ${(props) => props.width || 'auto'};
`;
