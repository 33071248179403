import i18next from 'i18next';

import en_account from '../../assets/locales/en/account.json';
import en_banner from '../../assets/locales/en/banner.json';
import en_campaign from '../../assets/locales/en/campaign.json';
import en_chat from '../../assets/locales/en/chat.json';
import en_client from '../../assets/locales/en/client.json';
import en_dashboard from '../../assets/locales/en/dashboard.json';
import en_guide from '../../assets/locales/en/guide.json';
import en_myJelly from '../../assets/locales/en/myJelly.json';
import en_notifications from '../../assets/locales/en/notifications.json';
import en_preset from '../../assets/locales/en/preset.json';
import en_settings from '../../assets/locales/en/settings.json';
import en_users from '../../assets/locales/en/users.json';
import jp_account from '../../assets/locales/jp/account.json';
import jp_banner from '../../assets/locales/jp/banner.json';
import jp_campaign from '../../assets/locales/jp/campaign.json';
import jp_chat from '../../assets/locales/jp/chat.json';
import jp_client from '../../assets/locales/jp/client.json';
import jp_dashboard from '../../assets/locales/jp/dashboard.json';
import jp_guide from '../../assets/locales/jp/guide.json';
import jp_myJelly from '../../assets/locales/jp/myJelly.json';
import jp_notifications from '../../assets/locales/jp/notifications.json';
import jp_preset from '../../assets/locales/jp/preset.json';
import jp_settings from '../../assets/locales/jp/settings.json';
import jp_users from '../../assets/locales/jp/users.json';
import kr_account from '../../assets/locales/kr/account.json';
import kr_banner from '../../assets/locales/kr/banner.json';
import kr_campaign from '../../assets/locales/kr/campaign.json';
import kr_chat from '../../assets/locales/kr/chat.json';
import kr_client from '../../assets/locales/kr/client.json';
import kr_dashboard from '../../assets/locales/kr/dashboard.json';
import kr_guide from '../../assets/locales/kr/guide.json';
import kr_myJelly from '../../assets/locales/kr/myJelly.json';
import kr_notifications from '../../assets/locales/kr/notifications.json';
import kr_preset from '../../assets/locales/kr/preset.json';
import kr_settings from '../../assets/locales/kr/settings.json';
import kr_users from '../../assets/locales/kr/users.json';

type TLng = 'en' | 'kr' | 'jp';
type TSheet =
  | 'account'
  | 'banner'
  | 'campaign'
  | 'chat'
  | 'client'
  | 'dashboard'
  | 'myJelly'
  | 'settings'
  | 'users'
  | 'guide'
  | 'preset'
  | 'notifications';

const lngs: TLng[] = ['en', 'kr', 'jp'];
const sheets: TSheet[] = [
  'account',
  'banner',
  'campaign',
  'chat',
  'client',
  'dashboard',
  'myJelly',
  'settings',
  'users',
  'guide',
  'preset',
  'notifications',
];
/**
 * Must add new language here
 * @param lng {Language} language
 * @param sheet
 * @returns {Object} json resource
 * https://lingohub.com/developers/supported-locales/language-designators-with-regions
 * 언어 코드는 ISO 639-1 표준을 따른다.
 */

const resourcesObj = {
  account: {
    en: en_account,
    kr: kr_account,
    jp: jp_account,
  },
  banner: { en: en_banner, kr: kr_banner, jp: jp_banner },
  campaign: { en: en_campaign, kr: kr_campaign, jp: jp_campaign },
  chat: { en: en_chat, kr: kr_chat, jp: jp_chat },
  client: { en: en_client, kr: kr_client, jp: jp_client },
  dashboard: { en: en_dashboard, kr: kr_dashboard, jp: jp_dashboard },
  myJelly: { en: en_myJelly, kr: kr_myJelly, jp: jp_myJelly },
  settings: { en: en_settings, kr: kr_settings, jp: jp_settings },
  users: { en: en_users, kr: kr_users, jp: jp_users },
  guide: {
    en: en_guide,
    kr: kr_guide,
    jp: jp_guide,
  },
  preset: {
    en: en_preset,
    kr: kr_preset,
    jp: jp_preset,
  },
  notifications: {
    en: en_notifications,
    kr: kr_notifications,
    jp: jp_notifications,
  },
};

function loadResource(lng: TLng, sheet: TSheet) {
  return resourcesObj[sheet]?.[lng] || {};
}

function getResources(lngs: TLng[]) {
  const resources: any = {};

  lngs.forEach((lng: TLng) => {
    resources[lng] = {
      translation: {}, // Initialize the translation object
    };

    sheets.forEach((sheet: TSheet) => {
      resources[lng].translation[sheet] = loadResource(lng, sheet);
    });
  });

  return resources;
}

export function initializeI18next(lng = 'kr') {
  i18next.init({
    lng,
    fallbackLng: { default: ['en', 'kr'] },
    returnEmptyString: false,
    keySeparator: '.',
    parseMissingKeyHandler(key) {
      console.warn('parseMissingKeyHandler', `'key': '${key}'`);
      const keySeparator = '.';
      const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;

      return value;
    },
    resources: getResources(lngs),
  });
}

export function changeLanguage(lng: TLng) {
  return i18next.changeLanguage(lng);
}

export function getLanguage() {
  return i18next.language;
}

export const { t } = i18next;

export const availableLanguage: {
  value: TLng;
  enLabel: string;
  nativeLabel: string;
}[] = [
  {
    value: 'en',
    enLabel: 'English',
    nativeLabel: 'English',
  },
  {
    value: 'kr',
    enLabel: 'Korean',
    nativeLabel: '한국어',
  },
  {
    value: 'jp',
    enLabel: 'Japanese',
    nativeLabel: '日本語',
  },
];
